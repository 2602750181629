import React from 'react';
import DateTime from 'luxon/src/datetime.js'
import Settings from 'luxon/src/settings.js'
import GroupTypeAction from '../utils/data/groupTypeAction';
import TeamsAction from '../utils/data/teamsAction';
import { Container, Row, Col } from "reactstrap";
import { Auth } from '../contexts/AuthProvider';
import { Store } from '../contexts/StoreProvider';
import { url, default_user, moveDateHoursForwardOrBack } from '../utils/const';
import { apiRequest } from '../utils/Helpers';
import ReactGA from 'react-ga';

import styles from './styles.module.scss';

ReactGA.initialize('UA-18994643-8');
ReactGA.pageview(window.location.pathname + window.location.search);

Settings.defaultLocale = "nl";
Settings.defaultZoneName = "Europe/Paris";

const DropDownTeams = React.lazy(() => import('../components/DropDownTeams'));

export default function UserTop4() {
  const { authState, authDispatch } = React.useContext(Auth);
  const { state, dispatch } = React.useContext(Store);
  // const [error, setError] = useState({ message: '', error: false });

  const now = DateTime.now().plus({ hours: moveDateHoursForwardOrBack });

  const tmpStartDate = state.grouptypes[0] !== undefined ?
    DateTime.fromSeconds(state.grouptypes.find(x => x.name === 'Groepswedstrijden').startTimeSec).plus({ hours: -1 }) : now.startOf('day');

  const tmpEndDate = state.grouptypes[0] !== undefined ?
    DateTime.fromSeconds(state.grouptypes.find(x => x.name === 'Achtste Finales').startTimeSec).plus({ hours: -1 }) : now.startOf('day');

  const tmpStart2Date = state.grouptypes[0] !== undefined ?
    DateTime.fromSeconds(state.grouptypes.find(x => x.order === state.grouptypes.find(x => x.name === 'Groepswedstrijden').order).endTimeSec) : now.startOf('day');

  const startDate = tmpStartDate.toFormat('dd MMMM HH:mm')
  const start2Date = tmpStart2Date.toFormat('dd MMMM HH:mm')
  const endDate = tmpEndDate.toFormat('dd MMMM HH:mm')

  const fetchDataAction = async () => {

    return await apiRequest(
      url.api.usertop4.getUserTop4,
      "get",
      authState.user.token
    ).then((result) => {

      if (result.status === 401) {
        // setError(true);

        return authDispatch({
          type: 'UNAUTH_USER',
          payload: default_user
        });
      }

      return dispatch({
        type: 'FETCH_DATA_USERTOP4',
        payload: result.usertop4
      });

    }).catch((e) => {

      // setError(true);
    });

  };

  const handleUserTop4Change = async (usertop4, index) => {

    try {
      await apiRequest(
        url.api.usertop4.upsert,
        "put",
        authState.user.token,
        { ...usertop4 }
      ).then((usertop4Result) => {
        return dispatch({
          type: 'UPDATE_USERTOP4',
          payload: { usertop4: usertop4Result.usertop4, index }
        });
      }).catch((err) => {

        // showError(err);
      });
    } catch (err) {
      //showError(err);
    }
  }

  React.useEffect(() => {
    fetchDataAction();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let places = [{ place: 1, id: 1 }, { place: 2, id: 2 }, { place: 3, id: 3 }, { place: 4, id: 4 }];

  if (now > tmpStart2Date) {
    places.unshift({ place: 1, id: 11 }, { place: 2, id: 22 }, { place: 3, id: 33 }, { place: 4, id: 44 });
  }

  const dropdowns = places.map((top, index) => {
    const props = {
      top4: state.usertop4,
      handleTop4Change: handleUserTop4Change,
      top: top,
      disabled: !((now > tmpStart2Date && now < tmpEndDate && top.id > 10) || (now < tmpStartDate && top.id < 10)),
      type: 'user'
    };

    return <React.Fragment key={`frag-${top.id}`}>
      <Row>
        <Col colSpan={2}>
          {
            top.id === 11 &&
            <p className={styles.info}>Invullen kan {start2Date !== '' ? `van ${start2Date}` : ''} tot {endDate}</p>
          }
                    {
            top.id === 1 &&
            <p className={styles.info}>Invullen kan tot {startDate}</p>
          }
        </Col>
      </Row>
      <Row>
        <Col xs={4} sm={2}>
          {top.place === 3 || top.place === 4 ? '3/4' : top.place}e plaats
          {/* {top.place}e plaats */}
        </Col>
        <Col xs={6} sm={3}>
          <DropDownTeams {...props} />
        </Col>
      </Row>
    </React.Fragment>
  });

  return (
    <React.Fragment>
      <GroupTypeAction />
      <TeamsAction />
      <React.Suspense fallback={<div>Laden...</div>}>
        <Container fluid>
          <Row>
            <Col><h1>Top 4 voorspelling</h1></Col>
          </Row>
          {dropdowns}
        </Container>
      </React.Suspense>
    </React.Fragment>
  );
}