import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { Auth } from '../contexts/AuthProvider';
import { Store } from '../contexts/StoreProvider';
import { url, default_user } from '../utils/const';
import { apiRequest } from '../utils/Helpers';

import styles from './styles.module.scss';

const DropDownTeams = React.lazy(() => import('../components/DropDownTeams'));

export default function Top4() {
  const { authState, authDispatch } = React.useContext(Auth);
  const { state, dispatch } = React.useContext(Store);
  // const [error, setError] = useState({ message: '', error: false });

  const fetchGroupTypeAction = async () => {
    return await apiRequest(
      url.api.grouptypes.getAll,
      "get",
      authState.user.token
    ).then((result) => {

      if (result.status === 401) {
        // setError(true);

        return authDispatch({
          type: 'UNAUTH_USER',
          payload: default_user
        });
      }

      return dispatch({
        type: 'FETCH_DATA_GROUPTYPES',
        payload: result.grouptypes
      });

    }).catch((e) => {

      // setError(true);
    });
  };

  const fetchTeamsAction = async () => {
    return await apiRequest(
      url.api.teams.getTop4teams,
      "get",
      authState.user.token
    ).then((result) => {

      if (result.status === 401) {
        // setError(true);

        return authDispatch({
          type: 'UNAUTH_USER',
          payload: default_user
        });
      }

      return dispatch({
        type: 'FETCH_DATA_TEAMS',
        payload: result.teams
      });

    }).catch((e) => {

      // setError(true);
    });

  };

  const fetchDataAction = async () => {

    return await apiRequest(
      url.api.top4.getTop4,
      "get",
      authState.user.token
    ).then((result) => {

      if (result.status === 401) {
        // setError(true);

        return authDispatch({
          type: 'UNAUTH_USER',
          payload: default_user
        });
      }

      return dispatch({
        type: 'FETCH_DATA_TOP4',
        payload: result.top4
      });

    }).catch((e) => {

      // setError(true);
    });

  };

  const handleTop4Change = async (top4, index) => {

    try {
      await apiRequest(
        url.api.top4.upsert,
        "put",
        authState.user.token,
        { ...top4 }
      ).then((top4Result) => {
        return dispatch({
          type: 'UPDATE_TOP4',
          payload: { top4: top4Result.top4, index }
        });
      }).catch((err) => {

        // setError(err);
      });
    } catch (err) {
      //setError(err);
    }
  }

  React.useEffect(() => {
    fetchTeamsAction();
    fetchDataAction();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    authState.user.isAuthed && state.grouptypes.length === 0 && fetchGroupTypeAction();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dropdowns = [{ place: 1, id: 1 }, { place: 2, id: 2 }, { place: 3, id: 3 }, { place: 4, id: 4 }].map((top, index) => {
    const props = {
      top4: state.top4,
      handleTop4Change,
      top: top,
      type: ''
    };

    return <React.Fragment key={`frag-${top.id}`}>
      <Row>
        <Col colSpan={2}>
          {
            top.id === 1 &&
            <React.Fragment>
              <p className={styles.info}>Invullen kan op ieder moment.</p>
            </React.Fragment>
          }
        </Col>
      </Row>
      <Row>
        <Col xs={4} sm={2}>
          {top.place === 3 || top.place === 4 ? '3/4' : top.place}e plaats
          {/* {top.place}e plaats */}
        </Col>
        <Col xs={6} sm={3}>
          <DropDownTeams {...props} />
        </Col>
      </Row>
    </React.Fragment>
  });

  return (
    <React.Fragment>
      <React.Suspense fallback={<div>Laden...</div>}>
        <Container fluid>
          <Row>
            <Col><h1>Top 4</h1></Col>
          </Row>
          {dropdowns}
        </Container>
      </React.Suspense>
    </React.Fragment>
  );
}