import React from 'react';
import { Auth } from '../../contexts/AuthProvider';
import { Button, Form, FormGroup, Input } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { apiRequest } from '../../utils/Helpers';
import { url, default_user } from '../../utils/const';
import { useNavigate } from "@reach/router"

import WindowStorage from '../../utils/storage';
import { LS_NAMES } from '../../utils/storage/consts';
import styles from './styles.module.scss';

const storage = WindowStorage('local');

export default function ResetForm(props) {
    const { authDispatch } = React.useContext(Auth);
    const [sent, setSent] = React.useState(false);

    const [userEmail, setUserEmail] = React.useState("");
    const [userPassword, setUserPassword] = React.useState("");
    const [userPassword2, setUserPassword2] = React.useState("");

    const { token } = props.props;

    const notify = (msg) => toast(`${msg}`);
    const navigate = useNavigate();

    const sendResetToken = async () => {
        try {
            await apiRequest(
                url.api.resetpassword,
                "post",
                "",
                { email: userEmail }
            ).then((result) => {

                setSent(result);
            }).catch((err) => {
                notify(err);
            });
        } catch (err) {
            notify(err);
        }
    };

    const changePassword = async () => {
        try {
            await apiRequest(
                url.api.changepassword,
                "post",
                "",
                { pwd: userPassword, pwd2: userPassword2, token }
            ).then((user) => {
                let dispatchObj = {
                    type: 'UNAUTH_USER',
                    payload: default_user
                }

                if (user.status === 500) {
                    notify(user.status);

                    return authDispatch({
                        type: 'UNAUTH_USER',
                        payload: default_user
                    });
                }

                const { id, email, token, firstName, lastName, admin } = user;
                storage.set(LS_NAMES.user, { id: id, name: `${firstName} ${lastName}`, email: email, token: token, admin: admin, super: user.super, isAuthed: true });

                dispatchObj = {
                    type: 'AUTH_USER',
                    payload: { id: id, name: `${firstName} ${lastName}`, email: email, token: token, admin: admin, super: user.super, isAuthed: true }
                }

                return authDispatch(dispatchObj);
            }).catch((err) => {
                notify(err);
            });
        } catch (err) {
            notify(err);
        } finally {
            navigate('/', { replace: true })
        }
    };

    return (
        <React.Fragment>
            <ToastContainer pauseOnHover={false} bodyClassName={styles.toastifyBody} progressClassName={styles.toastify} />
            <section>
                {token !== '0' && token !== undefined ?
                    <Form
                        onSubmit={e => {
                            e.preventDefault();
                            changePassword(userPassword, userPassword2);
                        }}
                    >
                        <FormGroup>
                            <Input
                                type="password"
                                name="password"
                                value={userPassword}
                                placeholder="Wachtwoord"
                                autoComplete="current-password"
                                onChange={e => setUserPassword(e.target.value)}
                                className={styles.input}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="password"
                                name="password2"
                                value={userPassword2}
                                placeholder="Herhaal Wachtwoord"
                                autoComplete="current-password"
                                onChange={e => setUserPassword2(e.target.value)}
                                className={styles.input}
                            />
                        </FormGroup>
                        <Button type="submit" block={true} className={styles.input}>
                            Wachtwoord Wijzigen
                        </Button>
                        <br />
                    </Form>
                    :
                    !sent &&
                    <Form
                        onSubmit={e => {
                            e.preventDefault();
                            sendResetToken(userEmail);
                        }}
                    >
                        <br />
                        <FormGroup>
                            <Input
                                type="email"
                                name="email"
                                value={userEmail}
                                placeholder="deroto@deroto.nl"
                                autoComplete="username"
                                onChange={e => setUserEmail(e.target.value)}
                                className={styles.input}
                            />
                        </FormGroup>
                        <Button type="submit" block={true} className={styles.input}>
                            Wachtwoord Wijzigen
                        </Button>
                        <br />
                    </Form>
                }
                {
                    sent &&
                    <React.Fragment>
                        Email Verzonden naar {userEmail}
                    </React.Fragment>
                }
            </section>
        </React.Fragment>
    );
};