import React from 'react';

export const AuthTokenName = 'x-ucm-authtoken';

// react
export const LoggedInUserCtx = React.createContext(false);

const apiBase = process.env.REACT_APP_API_PATH || 'http://localhost:3300';
export const moveDateHoursForwardOrBack = (apiBase === 'http://localhost:3300') ? 0 : 0;

// url
export const url = {
  api: {
    auth: `${apiBase}/users/authenticate`,
    register: `${apiBase}/users/register`,
    resetpassword: `${apiBase}/users/resetpassword`,
    changepassword: `${apiBase}/users/changepassword`,
    teams: {
      getAll: `${apiBase}/api/teams`,
      getTop4teams: `${apiBase}/api/top4teams`,
    },
    grouptypes: {
      getAll: `${apiBase}/api/grouptypes`,
    },
    games: {
      getAll: `${apiBase}/api/games`,
      upsert: `${apiBase}/api/games`,
      update: `${apiBase}/api/final`,
    },
    scores: {
      getAll: `${apiBase}/api/scores`,
      upsert: `${apiBase}/api/scores`,
    },
    overview: {
      getAllUsers: `${apiBase}/api/overview`,
      getScores: `${apiBase}/api/overview/users`,
    },
    ladder: {
      getLadder: `${apiBase}/api/ladder`,
    },
    users: {
      getAll: `${apiBase}/api/users`,
      update: `${apiBase}/api/users`,
    },
    top4: {
      getTop4: `${apiBase}/api/top4`,
      upsert: `${apiBase}/api/top4`,
    },
    usertop4: {
      getSpecificUserTop4: `${apiBase}/api/usertop4`,
      getUserTop4: `${apiBase}/api/usertop4`,
      upsert: `${apiBase}/api/usertop4`,
    },
  },
};

export const messages = {
  error: {
    wrongUserPass: `Log opnieuw in`,
    otherError: `Er is iets misgegaan, probeer opnieuw`,
    zeroFault: `Maximaal aantal 0-0 ruststanden deze ronde`,
    invalidTime: `Invoer is op het moment niet mogelijk`,
    emailinuse: `Dit emailadres is al in gebruik`
  },
  success: {
    resetInitiated: `Als uw email bekend is, dan ontvangt u een email met instructies`,
  }
};

export const default_user = {
  id: 0,
  name: '',
  email: '',
  token: '',
  admin: false,
  super: false,
  isAuthed: false,
};

export const default_model = {
  //example
  episodes: [],
  favourites: [],
  // deroto
  grouptypes: [],
  teams: [],
  groups: [],
  games: [],
  scores: [],
  overview: [],
  ladder: [],
  users: [],
  usertop4: [],
  top4: [],
}