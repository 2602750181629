import React, { useState } from 'react';
import { Auth } from '../../contexts/AuthProvider';
import { Store } from '../../contexts/StoreProvider';
import { Link, useNavigate } from '@reach/router';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    // Container, Row, Col
} from 'reactstrap';
import { default_user, default_model } from '../../utils/const';
import logo from '../../assets/images/UEFA_Euro_2024_Logo.svg.png'
import styles from './styles.module.scss';

import WindowStorage from '../../utils/storage';
import { LS_NAMES } from '../../utils/storage/consts';

const storage = WindowStorage('local');

export default function NavBar() {
    const { authState, authDispatch } = React.useContext(Auth);
    const { dispatch } = React.useContext(Store);

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    const navigate = useNavigate();

    const handleLogout = () => {
        try {
            const dispatchAuthObj = {
                type: 'UNAUTH_USER',
                payload: default_user
            };

            const dispatchObj = {
                type: 'EMPTY_STORE',
                payload: default_model
            };

            storage.remove(LS_NAMES.user);

            authDispatch(dispatchAuthObj);
            dispatch(dispatchObj);
        } catch (error) {

        }
        finally {
            navigate('/login', { replace: true })
        }
    }

    return (
        <React.Suspense fallback={<div>Laden...</div>}>
            <header className='header'>
                <div>
                    <Navbar color="light" light expand="md" className={styles.navBar}>
                        <NavbarBrand tag={Link} to="/" className={styles.brand}>
                            <img src={logo} alt="WK 2022 logo"></img>
                            Home
                        </NavbarBrand>
                        <NavbarToggler onClick={toggle} />
                        <Collapse isOpen={isOpen} navbar>

                            <Nav className="mr-auto" navbar>
                                {
                                    authState.user.isAuthed && (
                                        <React.Fragment>
                                            <NavItem className={styles.navItemInput}>
                                                <span>INVULLEN</span>
                                                <NavLink className={styles.current} tag={Link} to="/scores">Scores</NavLink>
                                                <NavLink className={styles.current} tag={Link} to="/usertop4">Top 4</NavLink>
                                            </NavItem>
                                            <NavItem className={styles.navItem}>
                                                <NavLink className={styles.current} tag={Link} to="/overview">Overzicht</NavLink>
                                            </NavItem>
                                            <NavItem className={styles.navItem}>
                                                <NavLink className={styles.current} tag={Link} to="/ladder">Stand</NavLink>
                                            </NavItem>
                                            <NavItem className={styles.navItem}>
                                                <NavLink className={styles.current} tag={Link} to="/games">Uitslagen</NavLink>
                                            </NavItem>
                                            {
                                                authState.user.admin &&
                                                <UncontrolledDropdown nav inNavbar className={styles.navDropdown}>
                                                    <DropdownToggle nav caret>
                                                        Admin
                                                    </DropdownToggle>
                                                    <DropdownMenu right className={styles.navDropdownMenu}>
                                                        <DropdownItem className={styles.current} tag={Link} to="/top4">
                                                            Einduitslag
                                                        </DropdownItem>
                                                        <DropdownItem divider />
                                                        <DropdownItem className={styles.current} tag={Link} to="/users">
                                                            Gebruikers
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            }
                                        </React.Fragment>
                                    )
                                }
                            </Nav>
                            <Nav navbar>
                                {
                                    authState.user.isAuthed ?
                                        <UncontrolledDropdown nav inNavbar className={styles.navDropdown}>
                                            <DropdownToggle nav caret title={authState.user.name}>
                                                {
                                                    // <Container>
                                                    //     <Row>
                                                    //         <Col xs={12} className="d-block d-md-none navvv">
                                                            authState.user.name.substr(0,1)+authState.user.name.substr(authState.user.name.lastIndexOf(' ')+1,1)
                                                    //         </Col>
                                                    //         <Col sm={12} className="d-none d-sm-block d-md-block navvv">
                                                    //         {authState.user.name}
                                                    //         </Col>
                                                    //     </Row>
                                                    // </Container>
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu right className={styles.navDropdownMenu}>
                                                <DropdownItem className={styles.current} onClick={handleLogout}>
                                                    Logout
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        :
                                        <React.Fragment>
                                            <NavItem>
                                                <NavLink className={styles.current} tag={Link} to="/login">Inloggen</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={styles.current} tag={Link} to="/register">Registreren</NavLink>
                                            </NavItem>
                                        </React.Fragment>
                                }
                            </Nav>
                        </Collapse>
                    </Navbar>
                </div>
            </header>
        </React.Suspense>
    );
};
