import {differenceInMinutes} from 'date-fns';
import {LS_TTL, SS_TTL} from './consts';

/**
 * @description prepares value to be stored
 * craetes {createdAt: Date.now(), key: value}
 * @param {String} key
 * @param {*} value
 * @returns {String}
 */
export function prepareToStore(key, value) {
  let lsVal = value;
  if (typeof value !== 'string') {
    lsVal = JSON.stringify(value);
  }

  const obj = {
    [key]: lsVal,
    createdAt: Date.now(),
  };

  return JSON.stringify(obj);
}

/**
 * @param {*} value
 * @returns JSON.parsed value || value itself
 */
export function prepareToReturn(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

/**
 * @description compares obj.createdAT against storage TTL
 * @param {String} storageType enum(local|session)
 * @param {Object} obj from storage
 */
export function hasExpired(storageType, obj) {
  if (storageType === 'local') {
    return differenceInMinutes(Date.now(), obj.createdAt) > LS_TTL;
  }
  return differenceInMinutes(Date.now(), obj.createdAt) > SS_TTL;
}
