import React from 'react';
import { Auth } from '../../contexts/AuthProvider';
import { Button, Form, FormGroup, Input, NavLink } from "reactstrap";
import { useErrorHandler } from '../../utils/ErrorHandler'
import { apiRequest } from '../../utils/Helpers';
import { url, default_user } from '../../utils/const';
import ErrorMessage from "../../components/ErrorMessage";
import styles from './styles.module.scss';
import { useNavigate, Link } from "@reach/router"

import WindowStorage from '../../utils/storage';
import { LS_NAMES } from '../../utils/storage/consts';

const storage = WindowStorage('local');

export default function LoginForm() {
    const { authState, authDispatch } = React.useContext(Auth);

    const [userEmail, setUserEmail] = React.useState("");
    const [userPassword, setUserPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const {error, showError} = useErrorHandler(null);

    const navigate = useNavigate();

    const setAuthStatusLocal = async () => {
        try {
            setLoading(true);

            let dispatchObj = {
                type: 'UNAUTH_USER',
                payload: default_user
            }

            await apiRequest(
                url.api.auth,
                "post",
                authState.user.token,
                { username: userEmail, password: userPassword }
            ).then((user) => {

                if (user.status === 401) {
                    showError(user.status);
            
                    return authDispatch(dispatchObj);
                  }

                const { id, email, token, firstName, lastName, admin } = user;

                storage.set(LS_NAMES.user, { id: id, name: `${firstName} ${lastName}`, email: email, token: token, admin: admin, super: user.super, isAuthed: true });

                dispatchObj = {
                    type: 'AUTH_USER',
                    payload: { id: id, name: `${firstName} ${lastName}`, email: email, token: token, admin: admin, super: user.super, isAuthed: true }
                }
            }).catch((err) => {
                showError(err);
            });

            return authDispatch(dispatchObj);
        } catch (err) {
            showError(err.message)
        } finally {
            navigate('/', { replace: true })
        }
    };

    return (
        <React.Suspense fallback={<div>Laden...</div>}>
            <section>
                {authState.user.isAuthed ?
                    ''
                    :
                    <Form
                        onSubmit={e => {
                            e.preventDefault();
                            //if (validateLoginForm(userEmail, userPassword, showError)) {
                            setAuthStatusLocal(userEmail, userPassword);
                            //}
                        }}
                    >
                        <br />
                        <FormGroup>
                            <Input
                                type="email"
                                name="email"
                                value={userEmail}
                                placeholder="deroto@deroto.nl"
                                autoComplete="username"
                                onChange={e => setUserEmail(e.target.value)}
                                className={styles.input}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="password"
                                name="password"
                                value={userPassword}
                                placeholder="Wachtwoord"
                                autoComplete="current-password"
                                onChange={e => setUserPassword(e.target.value)}
                                className={styles.input}
                            />
                        </FormGroup>
                        <Button type="submit" disabled={loading} block={true} className={styles.input}>
                            {loading ? "Laden..." : "Inloggen"}
                        </Button>
                        <br />
                        <NavLink className={styles.link} tag={Link} to="/resetpassword/0">wachtwoord vergeten</NavLink>
                        {error && <ErrorMessage errorMessage={error} />}
                    </Form>
                }
            </section>
        </React.Suspense>
    );
};