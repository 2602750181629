import React from 'react'
import { getStoredUserAuth } from '../../utils/Helpers';

export const Auth = React.createContext();

const initialState = {
    user: getStoredUserAuth()
}

function reducer(state, action) {
    switch (action.type) {
        case 'AUTH_USER':
            return {
                ...state,
                user: action.payload
            };
        case 'UNAUTH_USER':
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
}

export function AuthProvider(props) {
    const [authState, authDispatch] = React.useReducer(reducer, initialState);
    const value = { authState, authDispatch };

    return <Auth.Provider value={value}>{props.children}
    </Auth.Provider>
}