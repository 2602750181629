import React from 'react';
import GroupTypeAction from '../utils/data/groupTypeAction';
import LadderAction from '../utils/data/ladderAction';
import { Container, Row, Col, Table } from "reactstrap";

import styles from './styles.module.scss';

export default function App() {

  return (
    <React.Fragment>
      <GroupTypeAction />
      <React.Suspense fallback={<div>Laden...</div>}>
        <Container fluid>
          <Row>
            <Col>
              <h1 className={styles.h1LessPadding}>Stand</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Table>
                <thead>
                  <tr>
                    <th><b>#</b></th>
                    <td><b>Naam</b></td>
                    <td><b>Totaal</b></td>
                    <td><b>A</b></td>
                    <td><b>B</b></td>
                    <td><b>C</b></td>
                  </tr>
                </thead>
                <tbody>
                  <LadderAction />
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </React.Suspense>
    </React.Fragment>
  );
}