import React from 'react';
import { Auth } from '../../contexts/AuthProvider';
import { Store } from '../../contexts/StoreProvider';
import { apiRequest } from '../Helpers';
import { url } from '../const';
import { toast } from 'react-toastify';

const App = () => {
  const { authState } = React.useContext(Auth);
  const { state, dispatch } = React.useContext(Store);

  const notify = (msg) => toast(`${msg}`);

  const action = () => {
    apiRequest(
      url.api.grouptypes.getAll,
      "get",
      authState.user.token
    ).then((result) => {
      return dispatch({
        type: 'FETCH_DATA_GROUPTYPES',
        payload: result.grouptypes
      });
    }).catch((e) => {
      notify(e);
    });
  };

  React.useEffect(() => {
    state.grouptypes.length === 0 &&
      action();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <React.Fragment />;
}

export default App;