import React from 'react';
import GroupTypeAction from '../utils/data/groupTypeAction';
import { Container, Row, Col } from "reactstrap";
import { Link } from '@reach/router';

import styles from './styles.module.scss';

export default function App() {

  return (
    <React.Fragment>
      <GroupTypeAction />
      <React.Suspense fallback={<div>Laden...</div>}>
        <Container fluid>
          <Row>
            <Col xs={8}>
              <h1>DeRoTo EK2024!</h1>
              <p>20 juni 2024</p>
              <p>De Who's Who is weer beschikbaar! bekijk hem hier: <a rel="noreferrer" className={styles.link} href="/whowho">Who's Who 2024</a><br /><br />
              </p><br />
              <p>16 juni 2024</p>
              <p>De kop is eraf! In de eerste 4 potjes 16 doelpunten en 3 leuke wedstrijden gezien. Italië-Albanië was na 16 minuten niet meer om aan te gluren. Maar ja dat 
                kan je van Italië verwachten als ze voorstaan.<br /><br />
                Morgen om 3 uur ’s middags allemaal in het oranje voor de buis neem ik aan en maar eens zien hoe ons oranje het er van afbrengt tegen Polen, al of niet met 
                Lewandowski. Na de oefenwedstrijden tegen Canada en IJsland heeft iedereen weer een beetje meer hoop gekregen op een goed toernooi van het Nederlands elftal. 
                We gaan het zien morgen.<br /><br />
                Verder morgen de start van Engeland, die alom, en zeker bij de bookmakers in Engeland, tot de kanshebbers gerekend worden. Ze beginnen direct tegen Servië, 
                potentieel dus de nummers 1 en 2 in poule C. Je kan ze maar gehad hebben. Vlak echter de Denen nooit uit. Denk maar even terug aan 1992 toen ze overal van de 
                campings geplukt moesten worden als vervanger voor Joegoslavië en tegen ieders verwachting Europees kampioen werden.<br /><br />
              </p><br />
              <p>23 mei 2024</p>
              <p>Een paar kanttekeningen bij het invullen:<br /><br />
Je hebt tot 14 juni 20:00 uur de tijd (1 uur voor de start van de eerste wedstrijd) om je voorspelling voor de 36 poulewedstrijden 
en de eerste 4 van dit EK in te vullen. Zorg ervoor dat je de deadline niet mist!<br />
Wij zijn ouderwets, dus bij toto vul je een 3 (dus geen x) in als je denkt dat het een gelijkspel zal worden, een 1 als je 
denkt dat het eerste genoemde (thuis spelende) land zal winnen en een 2 als je denkt dat het tweede genoemde (uit spelende) 
land zal winnen.<br />
Het is geen enkel probleem om, mocht je dat willen, onlogisch in te vullen, dus bijvoorbeeld een 0-3 ruststand, een 2-1 
eindstand en een 3 als toto. Wij zullen dus ook niet checken op logica.<br />
Het kan zijn dat bij het invullen van de voorspelling van jouw eerste 4 van het EK er, behalve de namen van de landen, 
nog wat extra keuzemogelijkheden zijn (winnaar van etc.). Uiteraard kies je voor landnamen.<br />
Log na het invullen netjes uit, dan worden jouw voorspellingen opgeslagen.<br /><br />
<b>Extra info!</b><br />
              Je kan nog altijd makkelijk binnenkomen op onze chatapp door hieronder op de link te drukken.
              <br /><br />
              <a target="_blank" rel="noreferrer" className={styles.link} href="https://chat.whatsapp.com/EceGRuBnPxsIIANQWEYs2R">Bezoek hier de WhatsApp groep</a>
              <br /><br />
              <a target="_blank" rel="noreferrer" className={styles.link} href="https://docs.google.com/document/d/1044t9_yZDjDEgv7uIhaL5AuyoNoZXtda/edit?usp=drive_link&ouid=103967453027969726752&rtpof=true&sd=true">Bekijk hier het reglement</a>
              <br /><br />
              <a target="_blank" rel="noreferrer" className={styles.link} href="https://tikkie.me/pay/ftp8v6kdo5539nhp931k?utm_medium=qr">Je kunt Ome Willem betalen via deze tikkie link.</a>
              </p><br />
              <p>01 mei 2024</p>
              <p>
              De laatste keer dat het EK voetbal in (toen nog West-) Duitsland gehouden werd, werd het direct een hele mooie 
              zomer, dus wie weet… Nog even en de vlaggetjes en oranje spullen kunnen weer uit de kast en daarna kunnen we 
              minimaal 4.590 minuten officiële speeltijd voor de buis. Blessuretijd en verlengingen meegerekend kunnen die 
              minuten zomaar oplopen tot boven de 5.000.
              <br /><br />
              Uiteraard kan het EK voetbal niet van start gaan zonder dat je je voorspellingen bij onze EK2024TOTO. Wij denken 
              dat het echt om voetbalkennis gaat, maar achteraf blijkt vaak dat die kennis niet per se noodzakelijk is.
              <br /><br />
              Om met Charles (in sommige kringen Onno bijgenaamd) te spreken wordt het elk jaar relatief goedkoper om mee te 
              doen. Toen in 2002 de Euro werd ingevoerd werd de inleg al met zo’n 12% verlaagd van 25 gulden naar 10 Euro, 
              maar inmiddels is die Euro door inflatie nog eens 40% gekelderd. Dus eigenlijk speel je inmiddels mee voor de 
              helft van de inleg in de gulden-tijd. Kom daar in de supermarkt nog maar eens om.
              <br /><br />
              Het reglement hieronder laat zien hoe die kleine 1000 euro verdeeld werd en wordt, maar natuurlijk ook wat 
              er van je verwacht wordt. Onderaan zie je dat er 5 inlevermomenten zijn. Je kunt er geen missen, want los van 
              dat het niet handig is voor je kansen op de winst doe je dan ook voor de laatste 2 prijzen niet meer mee.
              <br /><br />
              Ferdi is vanuit Singapore weer bezig om de site up-to-date en gebruikersvriendelijk te krijgen en zoals altijd 
              vertrouwen we op eenieder om zijn of haar familie en vrienden bij de les te houden qua inleverdata.
              <br /><br />
              Nieuwe deelnemers zijn eventueel* ook welkom. Stuur deze mail dan door aan deze voetbalkenners, maar ook 
              aan de zogenaamde potspekkers (eigenlijk heb ikzelf ook meer ingelegd dan er uitgehaald).
              <br /><br />
              Zij kunnen zich dan ook aanmelden, met een omschrijving aan wie ze gelinkt willen worden in de who’s who. De who’s who van het laatste WK heb ik voor de volledigheid even bijgevoegd.
              <br /><br />
              Met sportieve groet namens de totocommissie, 
              <br />
              Willem Tolenaar<br /><br /><br />
              <b>Extra info!</b><br />
              Je kan nog altijd makkelijk binnenkomen op onze chatapp door hieronder op de link te drukken.
              <br /><br />
              <a target="_blank" rel="noreferrer" className={styles.link} href="https://chat.whatsapp.com/EceGRuBnPxsIIANQWEYs2R">Bezoek hier de WhatsApp groep</a>
              <br /><br />
              <a target="_blank" rel="noreferrer" className={styles.link} href="https://docs.google.com/document/d/1044t9_yZDjDEgv7uIhaL5AuyoNoZXtda/edit?usp=drive_link&ouid=103967453027969726752&rtpof=true&sd=true">Bekijk hier het reglement</a>
              <br /><br />
              Veel kijkplezier en een hoop leuke spanning gewenst met onze toto.</p>
              {/*<p><b>01 november 2022</b><br />
                Fijn, de eerste hobbel is genomen. Je bent binnen!<br />
                Nu alle lege hokjes invullen van de 48 poule wedstrijden bij SCORES en de 1e voorspelling TOP 4. <br />
                Wat betreft de toto: een 1 als je denkt dat het eerstgenoemde land wint, een 2 als je denkt dat het andere land wint en een 3 bij een gelijkspel.<br></br>
                Je kan nog altijd makkelijk binnenkomen op onze chatapp door hieronder op de link te drukken.
                <br /><br />
                <a target="_blank" rel="noreferrer" className={styles.link} href="https://www.ing.nl/particulier/betaalverzoek/index.html?trxid=5RadIhGPeboVPszceeMclFak6wUL95Hy">Betaalverzoek van Charles</a>
                <br /><br />
                <a target="_blank" rel="noreferrer" className={styles.link} href="https://chat.whatsapp.com/EceGRuBnPxsIIANQWEYs2R">Bezoek hier de WhatsApp groep</a>
                <br /><br />
                <a target="_blank" rel="noreferrer" className={styles.link} href="https://docs.google.com/document/d/1bVoXxENxB25gNo2aM0rxSoh0zKD7S-Ff/edit?usp=sharing&ouid=103967453027969726752&rtpof=true&sd=true">Bekijk hier het reglement</a>
                <br /><br />
                Veel kijkplezier en een hoop leuke spanning gewenst met onze toto.</p>
              <br /><br />
               <p><b>12 juni 2021</b><br />
                Zo, we zijn gestart. Vanaf 1 uur voor het begin van de wedstrijd van
                gisterenavond konden jullie zoals gepland niet meer invullen. Je kan zien wat de anderen hebben
                ingevuld bij overzicht. En wat er voorspeld is voor de 1e 4.<br />
                Indrukwekkend hoe het allemaal werkt en het eruitziet. Complimenten aan Ferdi!<br /><br />
                Er is meteen veel gescoord dankzij de verwachte overwinning van Italië. 2 koplopers met daarachter
                nog een samengepakt peleton, maar dat wordt na vandaag al langzaam meer gespreid.<br />
                101 deelnemers, wie had dat gedacht. De 1010 euro wordt verdeeld zoals beschreven op het
                reglement. Nr 51 en nr 100 vallen aan het eind ook in de prijzen. Dat kan een troost zijn als je
                onverhoopt toch wat minder scoort.<br />
                Aan groene balkjes in het overzicht kan je zien waar je gescoord hebt. In de poulefase scoor je 1
                punt voor een goede ruststand, 2 voor een goede eindstand en 4 voor een goede toto.<br /><br />
                Check je eigen score bij stand. Wij denken dat het allemaal goed werkt, maar je weet maar nooit.<br />
                Wij proberen kort na de wedstrijd en misschien ook al in de rust meteen de uitslag in te voeren. Dan is er
                direct weer een nieuwe tussenstand.<br /><br />
                <b>extra info!</b><br />
                Je kan nog altijd makkelijk binnenkomen op onze chatapp door hieronder op de link te drukken. Er zijn inmiddels 29 deelnemers.
                <br /><br />
                <a target="_blank" rel="noreferrer" className={styles.link} href="https://chat.whatsapp.com/EceGRuBnPxsIIANQWEYs2R">Bezoek hier de WhatsApp groep</a>
                <br /><br />
                <a target="_blank" rel="noreferrer" className={styles.link} href="https://drive.google.com/file/d/1O11jlW2lDsgMLFSXJHc7ba--oz-_iszn/view?usp=sharing">Bekijk hier het reglement</a>
                Veel kijkplezier en een hoop leuke spanning gewenst met onze toto.</p>
              <br /><br />
              <p><b>1 juni 2021</b><br />Fijn, de eerste hobbel is genomen. Je bent binnen!<br />
                Nu alle lege hokjes invullen van de 36 poule wedstrijden bij SCORES en de 1e voorspelling TOP 4. <br />
                Wat betreft de toto: een 1 als je denkt dat het eerstgenoemde land wint, een 2 als je denkt dat het andere land wint en een 3 bij een gelijkspel.<br></br>
                Daarna direct 10 euro overmaken naar NL66INGB0000199331 tnv C.I.M. Tolenaar en je doet echt mee.<br />
                Doe dit alles uiterlijk op donderdag 10 juni, dan heb je een dag marge en voorkom je invulstress.</p> */}

            </Col>
          </Row>
        </Container>
      </React.Suspense>
    </React.Fragment>
  );
}