import React from 'react';
import GroupTypeAction from '../utils/data/groupTypeAction';
import TeamsAction from '../utils/data/teamsAction';
import ScoreAction from '../utils/data/scoreAction';
import ReactGA from 'react-ga';
import { Container, Row, Col } from "reactstrap";
import { toast } from 'react-toastify';
import { Auth } from '../contexts/AuthProvider';
import { Store } from '../contexts/StoreProvider';
import { url } from '../utils/const';
import { apiRequest } from '../utils/Helpers';

import styles from './styles.module.scss';

ReactGA.initialize('UA-18994643-8');
ReactGA.pageview(window.location.pathname + window.location.search);

const GamesList = React.lazy(() => import('../components/GamesList'));

export default function Games() {
  const { authState } = React.useContext(Auth);
  const { state, dispatch } = React.useContext(Store);

  const notify = (msg) => toast(`${msg}`);

  const handleGameChange = async (gamePart, index) => {
    try {
      await apiRequest(
        url.api.games.upsert + '/' + gamePart.id,
        "put",
        authState.user.token,
        { ...gamePart }
      ).then((gameResult) => {
        return dispatch({
          type: 'UPDATE_GAME',
          payload: { game: gameResult.game, index }
        });
      }).catch((err) => {

        notify(err);
      });
    } catch (err) {
      notify(err);
    }
  }

  const handleGameTeamChange = async (game, index) => {

    try {
      await apiRequest(
        url.api.games.update + `/${game.homeaway}/` + game.id,
        "put",
        authState.user.token,
        { ...game }
      ).then((gameResult) => {
        return dispatch({
          type: 'UPDATE_GAMETEAM',
          payload: { team: {homeaway: game.homeaway, team: gameResult.team}, index: game.id }
        });
      }).catch((err) => {
  
        notify(err);
      });
    } catch (err) {
      notify(err);
    }
  }

  const props = {
    gameValues: state.scores,
    handleScoreChange: authState.user.admin ? handleGameChange : () => { },
    handleGameTeamChange,
    edit: authState.user.admin === true ? true : false,
    sameGroup: true,
    group: '',
    type: 'game',
  };

  return (
    <React.Fragment>
      <GroupTypeAction />
      <TeamsAction />
      <ScoreAction />
      <React.Suspense fallback={<div>Laden...</div>}>
        <Container fluid>
          <Row>
            <Col>
              <h1 className={styles.h1LessPadding}>Uitslagen</h1>
            </Col>
          </Row>
          <Row>
            <Col colSpan={2}>
              <Container>
                <GamesList {...props} />
              </Container>
            </Col>
          </Row>
        </Container>
      </React.Suspense>
    </React.Fragment>
  );
}