import React from 'react';
import { Auth } from '../../contexts/AuthProvider';
import { Store } from '../../contexts/StoreProvider';
import { apiRequest } from '../Helpers';
import { url } from '../const';
import { toast } from 'react-toastify';

const App = () => {
  const { authState } = React.useContext(Auth);
  const { state, dispatch } = React.useContext(Store);

  const notify = (msg) => toast(`${msg}`);

  const action = () => new Promise(async (resolve, reject) => {
    await apiRequest(
      url.api.teams.getTop4teams,
      "get",
      authState.user.token
    ).then((result) => {

      resolve(dispatch({
        type: 'FETCH_DATA_TEAMS',
        payload: result.teams
      }));

    }).catch((e) => {
      reject(e);
    });
  });

  React.useEffect(() => {
    state.teams.length === 0 &&
      action()
        .catch((e) => {
          notify(e);
        });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <React.Fragment />;
}

export default App;