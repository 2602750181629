import React from 'react';
import { Router } from '@reach/router';
import { Auth } from '../../contexts/AuthProvider';

import App from '../../App';

import Home from '../../pages/Home';
import Scores from '../../pages/Scores';
import UserTop4 from '../../pages/UserTop4';
import Top4 from '../../pages/Top4';
import Games from '../../pages/Games';
import Ladder from '../../pages/Ladder';
import Overview from '../../pages/Overview';
import WhoWho from '../../pages/WhoWho';
import Users from '../../pages/Users';
import Login from '../../pages/Login';
import Register from '../../pages/Register';
import ResetPassword from '../../pages/ResetPassword';

export function MainRouter() {
    const { authState } = React.useContext(Auth);

    return (
        <Router primary={false}>
            <App path='/'>
                {
                    authState.user.isAuthed && <Home path='/' />
                }
                {
                    authState.user.isAuthed && <Scores path='/scores' />
                }
                {
                    authState.user.isAuthed && <UserTop4 path='/usertop4' />
                }
                {
                    authState.user.isAuthed && <Overview path='/overview' />
                }
                {
                    authState.user.isAuthed && <Ladder path='/ladder' />
                }
                {
                    authState.user.isAuthed && <Games path='/games' />
                }
                {
                    authState.user.isAuthed && <WhoWho path='/whowho' />
                }
                {
                    authState.user.isAuthed && authState.user.admin && <Top4 path='/top4' />
                }
                {
                    authState.user.isAuthed && authState.user.admin && <Users path='/users' />
                }
                <Login path='/login' />
                <Register path='/register' />
                <ResetPassword path='/resetpassword/:token' />
            </App>
        </Router>
    );
}