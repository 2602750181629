import React from 'react';
import LoginForm from '../components/LoginForm';

export default function App() {
    return (
        <React.Fragment>
            <h1>Inloggen</h1>
            <LoginForm />
        </React.Fragment>
    );
}