import React from 'react';
import GroupTypeAction from '../utils/data/groupTypeAction';
import ScoreAction from '../utils/data/scoreAction';
import ReactGA from 'react-ga';
import { Container, Row, Col } from "reactstrap";
import { toast } from 'react-toastify';
import { Auth } from '../contexts/AuthProvider';
import { Store } from '../contexts/StoreProvider';
import { url } from '../utils/const';
import { apiRequest } from '../utils/Helpers';

import './styles.module.scss';

ReactGA.initialize('UA-18994643-8');
ReactGA.pageview(window.location.pathname + window.location.search);

const GamesList = React.lazy(() => import('../components/GamesList'));

export default function Scores() {
  const { authState } = React.useContext(Auth);
  const { state, dispatch } = React.useContext(Store);

  const notify = (msg) => toast(`${msg}`);

  const handleScoreChange = async (gamePart, index) => {
    try {
      await apiRequest(
        url.api.scores.upsert + '/' + (gamePart.id || '0'),
        "put",
        authState.user.token,
        { ...gamePart }
      ).then((gameResult) => {
        return dispatch({
          type: 'UPDATE_SCORE_TEAM',
          payload: { game: gameResult.game, index }
        });
      }).catch((err) => {

        notify(err);
      });
    } catch (err) {
      notify(err);
    }
  }

  const props = {
    gameValues: state.scores,
    handleScoreChange,
    edit: true,
    sameGroup: true,
    group: '',
    type: 'score',
  };

  return (
    <React.Fragment>
      <GroupTypeAction />
      <ScoreAction />
      <React.Suspense fallback={<div>Laden...</div>}>
        <Container fluid>
          <Row>
            <Col sm={10} xs={12}>
              <h1>Scores voorspelling</h1>
              <p><b>Let op!</b>&nbsp;&nbsp;Bij het eerste invulmoment en ook bij de achtste en kwartfinales mag u niet meer dan de
                helft van de wedstrijden een 0-0 ruststand geven.<br></br>
                <b>Bijvoorbeeld:</b> Als u de voorspelling geeft van de vier
                kwartfinales, dan mag u maximaal 2 keer een 0-0 ruststand invullen.</p>
            </Col>
            {/* <Col className='right'>
              <DropDown className='gameTypes' {...props} />
            </Col> */}
          </Row>
          <Row>
            <Col colSpan={2}>
              <Container>
                <GamesList {...props} />
              </Container>
            </Col>
          </Row>
        </Container>
      </React.Suspense>
    </React.Fragment>
  );
}