import React from 'react';
import GroupTypeAction from '../utils/data/groupTypeAction';
import { Container, Row, Col } from "reactstrap";

import who from '../assets/images/WhoWho.png'

import styles from './styles.module.scss';

export default function App() {

  return (
    <React.Fragment>
      <GroupTypeAction />
      <React.Suspense fallback={<div>Laden...</div>}>
        <Container fluid>
          <Row>
            <Col xs={12}>
              <h1>Who's Who 2024!</h1>
              <p>
              <a rel="noreferrer" className={styles.link} href="../assets/images/WhoWho.png"><img src={who} width="100%" alt="Who Who 2024"></img></a>
              </p>
            </Col>
          </Row>
        </Container>
      </React.Suspense>
    </React.Fragment>
  );
}