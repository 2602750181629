import React from 'react';
import { Auth } from '../../contexts/AuthProvider';
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { apiRequest } from '../../utils/Helpers';
import { url, default_user } from '../../utils/const';
import styles from './styles.module.scss';

import WindowStorage from '../../utils/storage';
import { LS_NAMES } from '../../utils/storage/consts';

const storage = WindowStorage('local');

export default function RegisterForm() {
    const { authState, authDispatch } = React.useContext(Auth);

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [userEmail, setUserEmail] = React.useState("");
    const [userPassword, setUserPassword] = React.useState("");
    const [userPasswordRepeat, setUserPasswordRepeat] = React.useState("");

    const notify = (msg) => toast(`${msg}`);

    const setRegister = async () => {
        try {
            let dispatchObj = {
                type: 'UNAUTH_USER',
                payload: default_user
            }

            await apiRequest(
                url.api.register,
                "post",
                authState.user.token,
                { username: userEmail, password: userPassword, firstName: firstName, lastName: lastName }
            ).then((user) => {
                const { id, email, token, firstName, lastName } = user;

                storage.set(LS_NAMES.user, { id: id, name: `${firstName} ${lastName}`, email: email, token: token, isAuthed: true });

                dispatchObj = {
                    type: 'AUTH_USER',
                    payload: { id: id, name: `${firstName} ${lastName}`, email: email, token: token, isAuthed: true }
                }
            }).catch((err) => {
                notify(err);
            });

            return authDispatch(dispatchObj);
        } catch (err) {
            notify(err.message)
        } 
    };

    const validateLoginForm = () => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (userPassword === userPasswordRepeat && re.test(userEmail)) {
            return true;
        }

        notify('Ongeldige waardes in formulier')
        return false;
    }

    return (
        <React.Fragment>
            <ToastContainer pauseOnHover={false} bodyClassName={styles.toastifyBody} progressClassName={styles.toastify} />
            <section>
                {authState.user.name !== '' ?
                    ''
                    :
                    <Form
                        onSubmit={e => {
                            e.preventDefault();
                            if (validateLoginForm()) {
                                setRegister(userEmail, userPassword, firstName, lastName);
                            }
                        }}
                    >
                        <br />
                        <FormGroup>
                            <Label for="firstName">Voornaam</Label>
                            <Input
                                type="firstName"
                                name="firstName"
                                value={firstName}
                                placeholder=""
                                autoComplete="given-name"
                                className={styles.input}
                                onChange={e => setFirstName(e.target.value)}
                            />
                            <Label for="lastName">Achternaam</Label>
                            <Input
                                type="lastName"
                                name="lastName"
                                value={lastName}
                                placeholder=""
                                autoComplete="family-name"
                                className={styles.input}
                                onChange={e => setLastName(e.target.value)}
                            />
                            <Label for="email">Email</Label>
                            <Input
                                type="email"
                                name="email"
                                value={userEmail}
                                placeholder="deroto@deroto.nl"
                                autoComplete="off"
                                className={styles.input}
                                onChange={e => setUserEmail(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Wachtwoord</Label>
                            <Input
                                type="password"
                                name="password"
                                value={userPassword}
                                placeholder="Wachtwoord"
                                autoComplete="off"
                                className={styles.input}
                                onChange={e => setUserPassword(e.target.value)}
                            />
                            <Label for="userPasswordRepeat">Herhaal Wachtwoord</Label>
                            <Input
                                type="password"
                                name="userPasswordRepeat"
                                value={userPasswordRepeat}
                                placeholder="Herhaal Wachtwoord"
                                autoComplete="off"
                                className={styles.input}
                                onChange={e => setUserPasswordRepeat(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button type="submit" block={true} className={styles.input}>
                                Registreren
                            </Button>
                            <br />
                        </FormGroup>
                    </Form>
                }
            </section>
        </React.Fragment>
    );
};