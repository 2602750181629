import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from './contexts/StoreProvider';
import { AuthProvider } from './contexts/AuthProvider';
import { MainRouter } from './components/MainRouter';

import './index.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
    <StoreProvider>
        <AuthProvider>
            <MainRouter />
        </AuthProvider>
    </StoreProvider>,
    document.getElementById('root')
);