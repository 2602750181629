import React, { useState } from 'react';
import DateTime from 'luxon/src/datetime.js'
import GroupTypeAction from '../utils/data/groupTypeAction';
import OverviewAction from '../utils/data/overviewAction';
import GameAction from '../utils/data/gameAction';
import { Container, Row, Col } from "reactstrap";
import { Auth } from '../contexts/AuthProvider';
import { Store } from '../contexts/StoreProvider';
import { BiRefresh } from 'react-icons/bi';

import styles from './styles.module.scss';

const OverviewList = React.lazy(() => import('../components/OverviewList'));
const OverviewGameList = React.lazy(() => import('../components/OverviewList/OverviewGameList'));

export default function App() {
  const { authState } = React.useContext(Auth);
  const { state } = React.useContext(Store);
  const [top4, setTop4] = useState(false);
  const [search, setSearch] = useState("");

  function refresh() {
    window.location.reload();
  }

  const [pouleStarted, setPouleStarted] = useState(false);

  const now = DateTime.now();

  const games = state.grouptypes.length > 0 && state.scores.map((game, index) => {

    const groupStartDateTime = DateTime.fromSeconds(state.grouptypes.find(x => x.id === game.groupTypeId).startTimeSec)

    return (
      (now > groupStartDateTime) || authState.user.admin ?
        <Row key={`game-${game.gameId}`} noGutters={true} className="ovv">
          <Col xs={3} className="d-block d-md-none">
            {game.homeTeamAlternate?.substr(0,3).toUpperCase() ?? game.homeTeamCode}
          </Col>
          <Col sm={6} className="d-none d-sm-block d-md-block">
            {
              (game.homeTeamAlternate == null) ? game.homeTeam : game.homeTeamAlternate
            }
          </Col>
          <Col xs={9} className="d-block d-md-none">
            &nbsp;&nbsp;{game.awayTeamAlternate?.substr(0,3).toUpperCase() ?? game.awayTeamCode}
          </Col>
          <Col sm={6} className="d-none d-sm-block d-md-block">
            {
              (game.awayTeamAlternate == null) ? game.awayTeam : game.awayTeamAlternate
            }
          </Col>
        </Row> : ''
    );
  });

  const props = {
    overview: state.overview,
    games: games,
    type: 'mobile',
    top4, setTop4, search
  };

  React.useEffect(() => {
    if (state.grouptypes !== null && state.grouptypes !== undefined && state.grouptypes.length > 0) {
      let pouleDateTime = DateTime.fromSeconds(state.grouptypes.find(x => x.order === 1).startTimeSec)

      setPouleStarted((now > pouleDateTime) || authState.user.admin);
    }
  }, [state.grouptypes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <GroupTypeAction />
      <OverviewAction />
      <GameAction />
      <React.Suspense fallback={<div>Laden...</div>}>
        <Container fluid>
          <Row>
            <Col>
              <h1 className={styles.h1LessPadding}>Overzicht</h1>
            </Col>
          </Row>
          <Row className={styles.filters}>
            <Col xs={4} sm={3}>
              {
                (pouleStarted) ? <button onClick={() => { setTop4(!top4) }}>{(top4 ? "verberg top 4" : "toon top 4")}</button> : ''
              }
            </Col>
            <Col xs={6}>
              {
                (pouleStarted) ? <input type="text" placeholder="zoek op naam" name="zoeken" id="zoeken" onKeyUp={(e) => { setSearch(e.target.value) }}></input> : ''
              }
            </Col>
            <Col xs={2}>
              {
                (pouleStarted) ? <BiRefresh onClick={() => { refresh(); }} className={styles.refresh} /> : ''
              }
            </Col>
          </Row>
          {
            state.overview.length === 0 ? <Row>
              <Col>
                Overzicht is nog niet zichtbaar
              </Col>
            </Row> : ''
          }
          <Row noGutters={true}>
            <Col xs={4}>
              <OverviewGameList {...props} />
            </Col>
            <Col xs={8}>
              <Container>
                <Row>
                  <OverviewList {...props} />
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </React.Suspense>
    </React.Fragment>
  );
}