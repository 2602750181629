import React from 'react';

export const useErrorHandler = (initialState) => {
    const [error, setError] = React.useState(initialState);

    const showError = (errorMessage) => {
        setError(errorMessage);
        window.setTimeout(() => {
            setError(null);
        }, 3000);
    };

    return { error, showError };
};