import React from 'react';
import Register from '../components/RegisterForm';

export default function App() {
    return (
        <React.Fragment>
            <h1>Registreren</h1>
            <Register />
        </React.Fragment>
    );
}