import React from 'react';
import { Auth } from '../../contexts/AuthProvider';
import { Store } from '../../contexts/StoreProvider';
import { apiRequest } from '../Helpers';
import { url } from '../const';
import { toast } from 'react-toastify';

const App = () => {
  const { authState } = React.useContext(Auth);
  const { state, dispatch } = React.useContext(Store);

  const notify = (msg) => toast(`${msg}`);
  let prevScore = -1;
  let currentPlace = 1;

  const action = () => {
    apiRequest(
      url.api.ladder.getLadder,
      "get",
      authState.user.token
    ).then((result) => {
      return dispatch({
        type: 'FETCH_DATA_LADDER',
        payload: result.ladder
      });
    }).catch((e) => {
      notify(e);
    });
  };

  React.useEffect(() => {
    state.ladder.length === 0 &&
      action();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return state.ladder.length > 0 ? state.ladder.map((user, index) => {
    if (prevScore !== user.totalscore) {
      currentPlace = index + 1;
    } else {
      currentPlace = ''
    }

    prevScore = user.totalscore;

    return (
      <tr key={`col-ladder-${user.userId}`}>
        <th scope="row">{currentPlace}</th>
        <td><b>{user.firstName} {user.lastName}</b></td>
        <td><b>{user.totalscore}</b></td>
        <td>{user.scorea}</td>
        <td>{user.scoreb}</td>
        <td>{user.scorec}</td>
      </tr>
    )
  }) : <tr><th></th></tr>;
}

export default App;