import React, { useState } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Auth } from '../contexts/AuthProvider';
import { Store } from '../contexts/StoreProvider';
import { url, default_user } from '../utils/const';
import { apiRequest } from '../utils/Helpers';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-18994643-8');
ReactGA.pageview(window.location.pathname + window.location.search);

const UsersList = React.lazy(() => import('../components/UsersList'));

export default function Users() {
  const { authState, authDispatch } = React.useContext(Auth);
  const { state, dispatch } = React.useContext(Store);
  const [error, setError] = useState({ message: '', error: false });

  const fetchDataAction = async () => {

    return await apiRequest(
      url.api.users.getAll,
      "get",
      authState.user.token
    ).then((result) => {

      if (result.status === 401) {
        setError(true);

        return authDispatch({
          type: 'UNAUTH_USER',
          payload: default_user
        });
      }

      return dispatch({
        type: 'FETCH_DATA_USERS',
        payload: result.users
      });

    }).catch((e) => {

      setError(true);
    });

  };

  const handleUserChange = async (user, index) => {

    try {
      await apiRequest(
        url.api.users.update + '/' + (user.id),
        "put",
        authState.user.token,
        { ...user }
      ).then((userResult) => {
        console.log(userResult.user);

        return dispatch({
          type: 'UPDATE_USER',
          payload: { user: userResult.user, index }
        });
      }).catch((err) => {

        //showError(err);
      });
    } catch (err) {
      //showError(err);
    }
  }

  const props = {
    users: state.users,
    handleUserChange,
  };

  React.useEffect(() => {
    authState.user.isAuthed && state.users.length === 0 && !error.error && fetchDataAction();
  });

  return (
    <React.Fragment>
      <React.Suspense fallback={<div>Laden...</div>}>
        <Container fluid>
          <Row>
            <Col>
              <h1>Gebruikers</h1>
              <p>Pagina voor het beheren van gebruikers die zichtbaar zijn in het overzicht.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              {
                error.error &&
                <div className="error">
                  {error.message}
                </div>
              }
            </Col>
          </Row>
          <Row>
            <Col colSpan={2}>
              <Container>
                {!error.error && <UsersList {...props} />}
              </Container>
            </Col>
          </Row>
        </Container>
      </React.Suspense>
    </React.Fragment>
  );
}