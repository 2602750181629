import React from 'react';
import NavBar from './components/NavBar';
import { Auth } from './contexts/AuthProvider';
import { useNavigate, useLocation } from "@reach/router"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-18994643-8');
ReactGA.pageview(window.location.pathname + window.location.search);


export default function App(props) {
  const { authState } = React.useContext(Auth);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (!authState.user.isAuthed && location.pathname !== '/register' && location.pathname.indexOf('/resetpassword') === -1) {
      navigate('/login', { replace: true })
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <NavBar />
      <ToastContainer pauseOnHover={false} bodyClassName={'toastifyBody'} progressClassName={'toastify'} />
      {props.children}
    </React.Fragment>
  );
}