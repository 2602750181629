import { default_user, messages } from '../utils/const';
import WindowStorage from '../utils/storage';
import { LS_NAMES } from '../utils/storage/consts';

const storage = WindowStorage('local');

/**
 * API Request handler
 * @param url - api endpoint
 * @param method - http method
 * @param bodyParams - body parameters of request
 */
export const apiRequest = async (url, method, token = '', bodyParams) => {
    return new Promise(async (resolve, reject) => {

        try {
            return fetch(url, {
                method,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                },
                body: bodyParams ? JSON.stringify(bodyParams) : undefined
            }).then((response) => {

                if (response.status === 401) {
                    reject(messages.error.wrongUserPass);
                } 

                if (response.status === 406) {
                    reject(messages.error.zeroFault);
                }

                if (response.status === 409) {
                    reject(messages.error.emailinuse);
                }

                if (response.status === 423) {
                    reject(messages.error.invalidTime);
                }

                if (response.status < 200 || response.status > 299) {
                    reject(messages.error.otherError);
                }

                if (response.status === 202) {
                    resolve(messages.error.resetInitiated);
                }

                resolve(response.json());
            });            
        } catch (error) {
            reject(error);
        }
    });
};

/** Return user auth from local storage value */
export const getStoredUserAuth = () => {
    const auth = storage.get(LS_NAMES.user);

    if (auth) {
        return auth;
    }
    return default_user;
};