import React from 'react'
import { default_model } from '../../utils/const';

export const Store = React.createContext();

const initialState = default_model

function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_DATA_TEAMS':
            return {
                ...state,
                teams: action.payload
            };
        case "FETCH_DATA_USERS":
            return {
                ...state,
                users: action.payload
            };
        case 'UPDATE_USER':
            const firstName = action.payload.user.firstName;
            const lastName = action.payload.user.lastName;
            const email = action.payload.user.email;
            const admin = action.payload.user.admin;
            const order = action.payload.user.order;
            const paid = action.payload.user.paid;

            state.users[action.payload.index].firstName = (firstName === '' ? null : firstName);
            state.users[action.payload.index].lastName = (lastName === '' ? null : lastName);
            state.users[action.payload.index].email = (email === '' ? null : email);
            state.users[action.payload.index].admin = (admin === '' ? null : admin);
            state.users[action.payload.index].order = (order === '' ? null : order);
            state.users[action.payload.index].paid = (paid === '' ? null : paid);

            return {
                ...state,
                users: state.users
            };
        case "FETCH_DATA_SCORES":
            return {
                ...state,
                scores: action.payload
            };
        case 'UPDATE_SCORE_TEAM':
            const score = action.payload.game;

            if (score.homeHT !== undefined)
                state.scores[action.payload.index].homeHT = score.homeHT;

            if (score.homeFT !== undefined)
                state.scores[action.payload.index].homeFT = score.homeFT;

            if (score.awayHT !== undefined)
                state.scores[action.payload.index].awayHT = score.awayHT;

            if (score.awayFT !== undefined)
                state.scores[action.payload.index].awayFT = score.awayFT;

            if (score.toto !== undefined)
                state.scores[action.payload.index].toto = score.toto;

            return {
                ...state,
                scores: state.scores
            };
        case "FETCH_DATA_GAMES":
            return {
                ...state,
                games: action.payload
            };
        case 'UPDATE_GAME':
            const gamePart = action.payload.game;

            if (gamePart.gameHomeHT !== undefined)
                state.scores[action.payload.index].gameHomeHT = gamePart.gameHomeHT;

            if (gamePart.gameHomeFT !== undefined)
                state.scores[action.payload.index].gameHomeFT = gamePart.gameHomeFT;

            if (gamePart.gameAwayHT !== undefined)
                state.scores[action.payload.index].gameAwayHT = gamePart.gameAwayHT;

            if (gamePart.gameAwayFT !== undefined)
                state.scores[action.payload.index].gameAwayFT = gamePart.gameAwayFT;

            if (gamePart.gameToto !== undefined)
                state.scores[action.payload.index].gameToto = gamePart.gameToto;

            return {
                ...state,
                scores: state.scores
            };
        case 'UPDATE_GAMETEAM':
            const homeaway = action.payload.team.homeaway;
            const team = action.payload.team.team;

            const game = state.scores.find(x => x.gameId === action.payload.index);
            game[`${homeaway}TeamAlternate`] = team.alternateName;

            return {
                ...state,
                scores: state.scores
            };
        case "FETCH_DATA_TOP4":
            return {
                ...state,
                top4: action.payload
            };
        case 'UPDATE_TOP4':
            return {
                ...state,
                top4: action.payload.top4
            };
        case "FETCH_DATA_USERTOP4":
            return {
                ...state,
                usertop4: action.payload
            };
        case 'UPDATE_USERTOP4':
            return {
                ...state,
                usertop4: action.payload.usertop4
            };
        case "FETCH_DATA_GROUPTYPES":
            return {
                ...state,
                grouptypes: action.payload
            };
        case "FETCH_DATA_LADDER":
            return {
                ...state,
                ladder: action.payload
            };
        case "FETCH_DATA_OVERVIEWUSERS":
            return {
                ...state,
                overview: action.payload
            };
        case "FETCH_DATA_OVERVIEWSCORE":
            const payloadScore = action.payload
            let userScore;

            if (payloadScore.length > 0) {
                userScore = state.overview.find(x => x.id === payloadScore[0].userId);

                if (userScore !== undefined) {
                    userScore.scores = payloadScore;
                }
            }

            return {
                ...state,
                overview: state.overview
            };
        case "FETCH_DATA_OVERVIEWTOP4":
            const payloadTop4 = action.payload
            let userTop4;

            if (payloadTop4.length > 0) {
                userTop4 = state.overview.find(x => x.id === payloadTop4[0].userId);

                if (userTop4 !== undefined) {
                    userTop4.top4 = payloadTop4;
                }
            }

            return {
                ...state,
                overview: state.overview
            };

        case 'EMPTY_STORE':
            return action.payload;
        default:
            return state;
    }
}

export function StoreProvider(props) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = { state, dispatch };

    return <Store.Provider value={value}>{props.children}
    </Store.Provider>
}