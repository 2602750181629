/* eslint no-console:0 */
import {LS_PREFIX as PREFIX} from './consts';
import {prepareToReturn, prepareToStore, hasExpired} from './utils';

/*
* window (local | session) storage wrapper:
- set:
  + stores values in object format with a createdAt key (set to Date.now())
  + i.e. name=jane will stored as name = {name: 'jane', createdAt: 18982498432}
- get:
  + returns stored valid values (without the createdAt key)
  + i.e. get(name) will return 'jane'
  + if value has expired, it will remove and return null
- remove:
  + deletes from storage
*/
const types = ['local', 'session'];
export default function init(type = 'local') {
  const storage = types.includes(type)
    ? window[`${type}Storage`]
    : window.localStorage;

  /**
   * @description appends PREFIX to key, then stores they pair in window storage
   * @param {String} key name
   * @param {*} value stored data
   * @return {Boolean}
   */
  function setItem(key, value) {
    const sName = `${PREFIX}${key}`;
    try {
      const sValue = prepareToStore(sName, value);
      storage.setItem(sName, sValue);
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  }

  /**
   * @description fetches data from window storage, attempts JSON.parse if failed, returns string
   * @param {String} key name (without PREFIX)
   * @returns {Object | String | null} value from window storage || null
   */
  function getItem(key = '') {
    const sName = `${PREFIX}${key}`;
    const data = storage.getItem(sName);
    if (!data) {
      return null;
    }
    try {
      const lsObj = JSON.parse(data);
      if (!lsObj || hasExpired(type, lsObj)) {
        removeItem(key); // remove if exists & has expired
        return null;
      }
      return prepareToReturn(lsObj[sName]); // return without createdAt key
    } catch (e) {
      console.error(e);
      console.error(`Unable to JSON.parse ${data}`);
      // should never reach here since we're only storing objects
      removeItem(key); // try again pls
      return data;
    }
  }

  function removeItem(key = '') {
    storage.removeItem(`${PREFIX}${key}`);
  }

  return {
    set: setItem,
    get: getItem,
    remove: removeItem,
  };
}
