import React from 'react';
import Reset from '../components/ResetForm';

export default function App(props) {
    return (
        <React.Fragment>
            <h1>Wachtwoord Wijzigen</h1>
            <Reset props={props} />
        </React.Fragment>
    );
}